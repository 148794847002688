import React from "react";
import { Helmet } from "react-helmet";
import seo from "../shared/seo";
const MyHelmet = ({ language, page, meta = {}, image }) => {
  const title = meta.title ? meta.title : seo[language][page]?.pageTitle;
  return (
    <Helmet>
      <title>{title}</title>
      <html lang={language} />
      <meta charSet="utf-8" />
      <meta name="description" content={title} />
      <meta name="p:domain_verify" content="7d3473573c7f412d71564fda8ddaa969" />
      <script type="application/ld+json">
        {`
        {
          "@context": "https://schema.org/",
          "@type": "BlogPosting",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://fastpack.dk${page}"
          },
          "headline": "${title}",
          "description": "${title}",
          "image": {
            "@type": "ImageObject",
            "url": "${image ||
              "https://server695021.nazwa.pl/wordpress/wpn_fp/wp-content/uploads/2021/07/pizzabox_B34.jpg"}",
            "width": "700",
            "height": "700"
          },
          "author": {
            "@type": "Person",
            "name": "Anna Wolthers"
          },
          "publisher": {
            "@type": "Organization",
            "name": "Fastpack",
            "logo": {
              "@type": "ImageObject",
              "url": "https://fastpack.dk/static/3d4f562d8881b086cd4b3ec65a80cf0a/fastpack_logo_www.png",
              "width": "69",
              "height": "60"
            }
          },
          "datePublished": "2020-11-22"
        }
      `}
      </script>
    </Helmet>
  );
};

export default MyHelmet;
