export default {
  en: {
    home: {
      pageTitle: "Custom gastronomy packaging with printing. Fast Pack",
      description:
        "Fast Pack - we produce extraordinary packaging made of cardboard and paper. Pizza, sushi boxes, take-out boxes, bags, stickers and accessories for gastronomy."
    },
    about: {
      pageTitle: "Meet the Fast Pack Team",
      description:
        "We have been advising, designing, manufacturing and supplying printed packaging to clients around the world for many years. We offer comprehensive solutions."
    },
    "case-studies": {
      pageTitle: "Packaging created for ... . Fast Pack",
      description: "Street Food, Sushi,"
    },
    products: {
      pageTitle:
        "Manufacturer of non-standard packaging for gastronomy with printing.",
      description:
        "Fast Pack - we produce extraordinary packaging made of cardboard and paper. Pizza, sushi boxes, take-out boxes, bags, stickers and accessories for gastronomy."
    },
    blog: {
      pageTitle:
        "Manufacturer of non-standard packaging for gastronomy with printing.",
      description:
        "Fast Pack - we produce extraordinary packaging made of cardboard and paper. Pizza, sushi boxes, take-out boxes, bags, stickers and accessories for gastronomy."
    },
    contact: {
      pageTitle:
        "Manufacturer of non-standard packaging for gastronomy with printing.",
      description:
        "Fast Pack - we produce extraordinary packaging made of cardboard and paper. Pizza, sushi boxes, take-out boxes, bags, stickers and accessories for gastronomy."
    }
  },
  dk: {
    home: {
      pageTitle:
        "Fast Pack - Bæredygtig og miljørigtig emballage og papir indpakning med tryk.",
      description:
        "Fast Pack - emballage af pap og papir til restauranter. Pizzabakker, sushibakker, take-away bakker, papirposer, sandwichpapir med tryk og tilbehør til gastronomi."
    },
    about: {
      pageTitle:
        "Fast Pack - Bæredygtig og miljørigtig emballage og papir indpakning med tryk.",
      description:
        "Fast Pack - emballage af pap og papir til restauranter. Pizzabakker, sushibakker, take-away bakker, papirposer, sandwichpapir med tryk og tilbehør til gastronomi."
    },
    "case-studies": {
      pageTitle:
        "Fast Pack - Bæredygtig og miljørigtig emballage og papir indpakning med tryk.",
      description:
        "Fast Pack - emballage af pap og papir til restauranter. Pizzabakker, sushibakker, take-away bakker, papirposer, sandwichpapir med tryk og tilbehør til gastronomi."
    },
    products: {
      pageTitle:
        "Fast Pack - Bæredygtig og miljørigtig emballage og papir indpakning med tryk.",
      description:
        "Fast Pack - emballage af pap og papir til restauranter. Pizzabakker, sushibakker, take-away bakker, papirposer, sandwichpapir med tryk og tilbehør til gastronomi."
    },
    blog: {
      pageTitle:
        "Fast Pack - Bæredygtig og miljørigtig emballage og papir indpakning med tryk.",
      description:
        "Fast Pack - emballage af pap og papir til restauranter. Pizzabakker, sushibakker, take-away bakker, papirposer, sandwichpapir med tryk og tilbehør til gastronomi."
    },
    contact: {
      pageTitle:
        "Fast Pack - Bæredygtig og miljørigtig emballage og papir indpakning med tryk.",
      description:
        "Fast Pack - emballage af pap og papir til restauranter. Pizzabakker, sushibakker, take-away bakker, papirposer, sandwichpapir med tryk og tilbehør til gastronomi."
    }
  },
  es: {
    home: {
      pageTitle: "Custom gastronomy packaging with printing. Fast Pack",
      description:
        "Fast Pack - we produce extraordinary packaging made of cardboard and paper. Pizza, sushi boxes, take-out boxes, bags, stickers and accessories for gastronomy."
    },
    about: {
      pageTitle: "Meet the Fast Pack Team",
      description:
        "We have been advising, designing, manufacturing and supplying printed packaging to clients around the world for many years. We offer comprehensive solutions."
    },
    "case-studies": {
      pageTitle: "Packaging created for ... . Fast Pack",
      description: "Street Food, Sushi,"
    },
    products: {
      pageTitle:
        "Manufacturer of non-standard packaging for gastronomy with printing.",
      description:
        "Fast Pack - we produce extraordinary packaging made of cardboard and paper. Pizza, sushi boxes, take-out boxes, bags, stickers and accessories for gastronomy."
    },
    blog: {
      pageTitle:
        "Manufacturer of non-standard packaging for gastronomy with printing.",
      description:
        "Fast Pack - we produce extraordinary packaging made of cardboard and paper. Pizza, sushi boxes, take-out boxes, bags, stickers and accessories for gastronomy."
    },
    contact: {
      pageTitle:
        "Manufacturer of non-standard packaging for gastronomy with printing.",
      description:
        "Fast Pack - we produce extraordinary packaging made of cardboard and paper. Pizza, sushi boxes, take-out boxes, bags, stickers and accessories for gastronomy."
    }
  },
  de: {
    home: {
      pageTitle: "Custom gastronomy packaging with printing. Fast Pack",
      description:
        "Fast Pack - we produce extraordinary packaging made of cardboard and paper. Pizza, sushi boxes, take-out boxes, bags, stickers and accessories for gastronomy."
    },
    about: {
      pageTitle: "Meet the Fast Pack Team",
      description:
        "We have been advising, designing, manufacturing and supplying printed packaging to clients around the world for many years. We offer comprehensive solutions."
    },
    "case-studies": {
      pageTitle: "Packaging created for ... . Fast Pack",
      description: "Street Food, Sushi,"
    },
    products: {
      pageTitle:
        "Manufacturer of non-standard packaging for gastronomy with printing.",
      description:
        "Fast Pack - we produce extraordinary packaging made of cardboard and paper. Pizza, sushi boxes, take-out boxes, bags, stickers and accessories for gastronomy."
    },
    blog: {
      pageTitle:
        "Manufacturer of non-standard packaging for gastronomy with printing.",
      description:
        "Fast Pack - we produce extraordinary packaging made of cardboard and paper. Pizza, sushi boxes, take-out boxes, bags, stickers and accessories for gastronomy."
    },
    contact: {
      pageTitle:
        "Manufacturer of non-standard packaging for gastronomy with printing.",
      description:
        "Fast Pack - we produce extraordinary packaging made of cardboard and paper. Pizza, sushi boxes, take-out boxes, bags, stickers and accessories for gastronomy."
    }
  },
  fr: {
    home: {
      pageTitle: "Custom gastronomy packaging with printing. Fast Pack",
      description:
        "Fast Pack - we produce extraordinary packaging made of cardboard and paper. Pizza, sushi boxes, take-out boxes, bags, stickers and accessories for gastronomy."
    },
    about: {
      pageTitle: "Meet the Fast Pack Team",
      description:
        "We have been advising, designing, manufacturing and supplying printed packaging to clients around the world for many years. We offer comprehensive solutions."
    },
    "case-studies": {
      pageTitle: "Packaging created for ... . Fast Pack",
      description: "Street Food, Sushi,"
    },
    products: {
      pageTitle:
        "Manufacturer of non-standard packaging for gastronomy with printing.",
      description:
        "Fast Pack - we produce extraordinary packaging made of cardboard and paper. Pizza, sushi boxes, take-out boxes, bags, stickers and accessories for gastronomy."
    },
    blog: {
      pageTitle:
        "Manufacturer of non-standard packaging for gastronomy with printing.",
      description:
        "Fast Pack - we produce extraordinary packaging made of cardboard and paper. Pizza, sushi boxes, take-out boxes, bags, stickers and accessories for gastronomy."
    },
    contact: {
      pageTitle:
        "Manufacturer of non-standard packaging for gastronomy with printing.",
      description:
        "Fast Pack - we produce extraordinary packaging made of cardboard and paper. Pizza, sushi boxes, take-out boxes, bags, stickers and accessories for gastronomy."
    }
  }
};
