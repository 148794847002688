import React, { useState, useContext, useEffect } from "react";
import styles from "./Cookies.module.css";
import { Container } from "react-bootstrap";
import { pageElements } from "../../shared/pageElements";
import { GlobalStateContext } from "../../context/GlobalContextProvider";
import { langPrefix } from "../../shared/scripts";
import { Link } from "gatsby";

const Cookies = () => {
  const { language } = useContext(GlobalStateContext);
  const { cookies } = pageElements[language];
  const [agreed, setAgreed] = useState(false);

  useEffect(() => {
    if (window.localStorage.getItem("agree")) {
      setAgreed(true);
    }
  }, []);

  const handleClick = e => {
    e.preventDefault();
    window.localStorage.setItem("agree", "true");
    setAgreed(true);
  };

  return (
    !agreed && (
      <div className={styles.self}>
        <Container className={styles.container}>
          <div>
            {cookies.text}{" "}
            <Link to={`${langPrefix(language)}cookies`}>
              {cookies.readMore}
            </Link>
          </div>
          <button className={styles.button} onClick={handleClick}>
            {cookies.button}
          </button>
        </Container>
      </div>
    )
  );
};

export default Cookies;
