import React from "react";
import Layout from "./Layout";
import Cookies from "../Cookies";

const LayoutMain = ({ children }) => {
  return (
    <>
      <Layout links={{ en: "", dk: "", es: "", de: "", fr: "" }}>
        {children}
      </Layout>
      <Cookies />
    </>
  );
};

export default LayoutMain;
