export default {
  en: {
    title: "Oops! We can't fing the page that you're looking for.",
    subtitle: "Here are a handful of useful links",
    links: [
      { link: "/", text: "Return to main page" },
      { link: "/products", text: "See our products" },
      { link: "/about", text: "Read about Fastpack" },
      { link: "/case-studies", text: "Our projects" },
      { link: "/contact", text: "Contact us" },
      { link: "/blog", text: "Blog" }
    ]
  },
  dk: {
    title: "Ups! Vi kan ikke finde den side, du leder efter.",
    subtitle: "Her er en håndfuld nyttige links",
    links: [
      { link: "/", text: "Vend tilbage til hovedsiden" },
      { link: "/products", text: "Se vores produkter." },
      { link: "/about", text: "Læs om Fastpack" },
      { link: "/case-studies", text: "Vores projekter" },
      { link: "/contact", text: "Kontakt os" },
      { link: "/blog", text: "Blog" }
    ]
  },
  fr: {
    title: "Oops! We can't fing the page that you're looking for.",
    subtitle: "Here are a handful of useful links",
    links: [
      { link: "/", text: "Return to main page" },
      { link: "/products", text: "See our products" },
      { link: "/about", text: "Read about Fastpack" },
      { link: "/case-studies", text: "Our projects" },
      { link: "/contact", text: "Contact us" },
      { link: "/blog", text: "Blog" }
    ]
  }
};
