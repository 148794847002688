import React, { useContext } from "react";
import styles from "./Page404.module.css";
import { Link } from "gatsby";
import { langPrefix } from "../../shared/scripts";
import { GlobalStateContext } from "../../context/GlobalContextProvider";
import { graphql, useStaticQuery } from "gatsby";
import text from "./text";

const Page404 = () => {
  const state = useContext(GlobalStateContext);
  const { file } = useStaticQuery(
    graphql`
      query {
        file(name: { eq: "page-404" }) {
          name
          publicURL
        }
      }
    `
  );
  const lang = state.language || "en";
  const { title, subtitle, links } = text[lang];
  return (
    <div
      className={styles.self}
      style={{ backgroundImage: `url(${file.publicURL})` }}
    >
      <div className={styles.text}>
        <h2>{title}</h2>
        <p className={styles.subtitle}>{subtitle}</p>
        {links.map(l => (
          <>
            <Link
              key={`404link-${l.link}`}
              className={styles.link}
              to={`${langPrefix(lang)}${l.link}`}
              activeClassName={styles.active}
            >
              {l.text}
            </Link>
            <br />
          </>
        ))}
      </div>
    </div>
  );
};

export default Page404;
